import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import {
  QuotesSection,
  BenefitsAdvisorSection,
  ReasonsToBelieveSection,
  OneAppSection,
} from 'src/sections';
import { Page, SplitHero, Section, BlankBox, Lineup, Zip } from 'src/components';
import { $t, locale } from 'src/utils';
import SessionContext from 'src/context/SessionContext';

const SImg = styled.img`
  filter: var(--svgFilter);
  height: 72px;
  margin-bottom: 12px;
  opacity: 0.5;
  @media (prefers-color-scheme: dark) {
    filter: var(--svgFilter) saturate(0%) brightness(200%);
  }
  @media (max-width: 800px) {
    margin: 0;
  }
`;

const QUERY = graphql`
  query HomeFaq {
    en: allGraphCmsFaq(filter: { featured: { eq: false }, locale: { eq: en } }) {
      nodes {
        title
        explanation
      }
    }
    es: allGraphCmsFaq(filter: { featured: { eq: false }, locale: { eq: es } }) {
      nodes {
        title
        explanation
      }
    }
  }
`;

const HomePage = ({ pageContext = {} }) => {
  const data = useStaticQuery(QUERY);
  const faqs = data?.[locale]?.nodes;

  const { source: _source, code, name, title, slug, promo, personas = [] } = pageContext;
  const { source, signupUrl, setSource, setPartner, setCode } = useContext(SessionContext);

  useEffect(() => {
    if (setSource) {
      setSource(_source);
      setPartner(name);
      setCode(code);
    }
  }, [setSource]);

  return (
    <Page
      explicitBannerColor={!!code}
      name={name || source}
      kw={[source, name, ...personas]}
      path={slug || code}
      signupUrl={signupUrl}
      metadata={{ faqs }}
      navBorder={false}
    >
      <SplitHero
        title={
          !!title ? (
            <>{title}</>
          ) : (
            <>{$t({ id: 'get-started.title', defaultMessage: 'Healthcare for the self-employed' })}</>
          )
        }
        subtitle={$t({
          id: 'home.subtitle',
          defaultMessage: 'Find health, dental, and vision insurance that fits your life.',
        })}
        accessory={
          <BlankBox color="var(--g-cerise)" screen>
            <img src="/screens/22-home.webp" height={600} alt="Phone depicting Catch home screen" />
          </BlankBox>
        }
        promo={promo}
      >
        <Zip cta="Get started" />
      </SplitHero>
      <Section small>
        <Lineup height={48} numPerRow={8}>
          <SImg loading="lazy" src="/logos/standard/nyt.webp" />
          <SImg loading="lazy" src="/logos/standard/wsj.webp" />
          <SImg loading="lazy" src="/logos/standard/techcrunch.webp" />
          <SImg loading="lazy" src="/logos/standard/forbes.webp" />
          <SImg loading="lazy" src="/logos/standard/fortune.webp" />
          <SImg loading="lazy" src="/logos/standard/bi.png" />
          <SImg loading="lazy" src="/logos/standard/realsimple.webp" />
          <SImg loading="lazy" src="/logos/standard/entrepreneur.webp" />
        </Lineup>
      </Section>

      <ReasonsToBelieveSection showCTA />
      <BenefitsAdvisorSection />
      <QuotesSection />
      <OneAppSection title="Start shopping today">
        <Zip cta="Get started" />
      </OneAppSection>
    </Page>
  );
};

export default HomePage;
